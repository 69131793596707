const devPlans = {
  monthly: [
    { id: "price_1QNdk3AovBBsdBLoiXexbxyW", price: 1999, staff: "1 user" },
    { id: "price_1QNe20AovBBsdBLo7WiN5b9p", price: 3999, staff: "2 users" },
    { id: "price_1QNe1NAovBBsdBLo0fPmtbFq", price: 4999, staff: "3 users" },
    { id: "price_1QNe4HAovBBsdBLo5HG45O9q", price: 6999, staff: "4-5 users" },
    { id: "price_1QNe42AovBBsdBLo8Iz5DxLM", price: 8999, staff: "6-7 users" },
    { id: "price_1QNe3XAovBBsdBLoXEiG0KDd", price: 10999, staff: "8-9 users" },
    { id: "price_1QNe2wAovBBsdBLo5wepIUly", price: 12999, staff: "10-11 users" },
    { id: "price_1QNe2KAovBBsdBLojnNf3y60", price: 16999, staff: "12+ users" },
  ],
}

const prodPlans = {
  monthly: [
    { id: "price_1QNdv4AovBBsdBLow1felXk4", price: 1999, staff: "1 user" },
    { id: "price_1QNdw9AovBBsdBLoWe6EH3xP", price: 3999, staff: "2 users" },
    { id: "price_1QNdwXAovBBsdBLopLsXB83w", price: 4999, staff: "3 users" },
    { id: "price_1QNdxTAovBBsdBLoJzLAlkMU", price: 6999, staff: "4-5 users" },
    { id: "price_1QNdxxAovBBsdBLolrHiN6vh", price: 8999, staff: "6-7 users" },
    { id: "price_1QNdylAovBBsdBLo9oremK6M", price: 10999, staff: "8-9 users" },
    { id: "price_1QNdzGAovBBsdBLoVMVzumJj", price: 12999, staff: "10-11 users" },
    { id: "price_1QNdzuAovBBsdBLoqZ1Y49i0", price: 16999, staff: "12+ users" },
  ],
}

const plans = process.env.NODE_ENV === "development" ? devPlans : prodPlans

export { plans }
